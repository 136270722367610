import { PortableText } from '@portabletext/react';
import Link from 'next/link';
import config from '../config';
import ui from './../ui';
import { srcSet } from './../ui/FrontRichTextEditor/slate/elements';
import _ from './../_';
import { convertPlainTextContentToRich, isObject } from '../lib/helpers';
import styled from 'styled-components';
import { colors, effects } from '/css';
import RichTextType from '../types/frontRichTextType';
import {
    BlockContentMarkDef,
    Format,
    TextFormat,
} from './../ui/FrontRichTextEditor/types';

const RichTextContent = ({
    value,
    maxWidth,
}: {
    value: RichTextType;
    maxWidth: number;
}) => (
    <PortableText
        value={value}
        components={{
            types: {
                image: () => null,
            },
            block: {
                image: ({ value }) => {
                    if (!value.markDefs || !isObject(value.markDefs[0]))
                        return null;

                    const markDef = value.markDefs[0] as BlockContentMarkDef;

                    const width = markDef.width
                        ? maxWidth * markDef.width
                        : maxWidth;

                    const src = markDef.metadata
                        ? config
                              .imageUrl({
                                  url: markDef.url || '',
                                  crop: markDef.crop || null,
                                  hotspot: markDef.hotspot || null,
                                  metadata: markDef.metadata,
                                  alt: markDef.alt || '',
                                  category: null,
                                  originalFilename: '',
                                  size: 0,
                              })
                              .width(width)
                              .url()
                        : markDef.url || '';

                    const imageEl = (
                        <img
                            src={src}
                            srcSet={srcSet(src)}
                            alt={markDef.alt || 'image'}
                        />
                    );

                    if (markDef.link?.url) {
                        return (
                            <Link
                                prefetch={false}
                                href={markDef.link?.url}
                                target={markDef.link?.target || '_self'}
                                className={[
                                    markDef.float &&
                                        `rich-img-float-${markDef.float}`,
                                ]
                                    .filter((c) => c)
                                    .join(' ')}
                            >
                                {imageEl}
                            </Link>
                        );
                    }

                    return (
                        <div
                            className={[
                                markDef.float &&
                                    `rich-img-float-${markDef.float}`,
                            ]
                                .filter((c) => c)
                                .join(' ')}
                        >
                            {imageEl}
                        </div>
                    );
                },
            },
            marks: {
                internalLink: ({ value, children }) => {
                    const linkProps = config.pageUrl({
                        _id: value?.reference?._ref || value?.page?.id,
                        _type: value?.document?.type || value?.page?.type,
                        slug: value?.document?.slug || value?.page?.slug,
                        path: value?.document?.path || value?.page?.path,
                    });
                    return (
                        <Link
                            prefetch={false}
                            {...linkProps}
                            target={value?.blank ? '_blank' : '_self'}
                        >
                            {children}
                        </Link>
                    );
                },
                link: ({ value, children }) => {
                    return (
                        <a
                            href={value?.href}
                            target={value?.blank ? '_blank' : '_self'}
                            rel={value?.blank ? 'noreferrer' : undefined}
                        >
                            {children}
                        </a>
                    );
                },
            },
        }}
    />
);

interface Props {
    value: RichTextType | string | null | undefined;
    onChange?: (content: RichTextType) => void;
    className?: string;
    editorOnFocus?: boolean;
    editable?: boolean;
    placeholder?: string;
    disabledOptions?: (Format | TextFormat)[];
    maxWidth?: number;
}

interface StaticProps extends Props {
    editable?: false;
}

interface EditableProps extends Props {
    editable: true;
    onChange: (content: RichTextType) => void;
}

export default function RichText({
    value,
    onChange,
    className = '',
    editable,
    placeholder = 'Skriv her...',
    disabledOptions = [],
    maxWidth = 1160,
}: Props) {
    const { editMode: frontEditMode, FrontRichTextEditor } = ui();

    const mappedValue = convertPlainTextContentToRich(value);

    const editMode = frontEditMode || editable === true;

    if (editable === false || (value && !editMode))
        return (
            <div className={className}>
                <RichTextContent value={mappedValue} maxWidth={maxWidth} />
            </div>
        );

    if (editMode)
        return (
            <FrontRichTextEditor
                className={className}
                content={mappedValue}
                onChange={onChange!}
                placeholder={placeholder}
                disabledOptions={disabledOptions}
                maxWidth={maxWidth}
            />
        );

    return null;
}

export const RichTextInputWrapper = styled.div.attrs(({ className }) => ({
    className: 'p-24 ' + className,
}))`
    border: 3px solid white;
    background: #fff;
    outline: none;
    border-radius: 12px;
    ${effects.shadow};
    font-size: 17px;
    color: ${colors.black};
    transition: all 0.2s ease-in-out;
    &:focus {
        border: 3px solid #7e69fd;
    }

    &:not(.disabled):hover {
        ${effects.shadowHover};
    }

    > div {
        outline: 0 !important;
    }
`;
