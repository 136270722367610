import ui from './../ui';
import { CSSProperties } from 'react';

interface Props {
    value: string;
    onChange: (value: string) => void;
    placeholder?: string;
    multiline?: boolean;
    className?: string;
    style?: CSSProperties;
    placeholderStyle?: CSSProperties;
    tag?: keyof JSX.IntrinsicElements;
}

export default function Text({
    value,
    onChange,
    placeholder = '',
    multiline = false,
    className = '',
    style = {},
    placeholderStyle = {},
    tag,
}: Props) {
    const { editMode, FrontEditable } = ui();

    const Tag = tag || 'div';

    if (!editMode)
        return (
            <Tag
                className={className}
                style={{ ...style, whiteSpace: 'pre-wrap' }}
            >
                {value}
            </Tag>
        );

    return (
        <FrontEditable
            value={value}
            onChange={onChange}
            placeholder={placeholder}
            multiline={multiline}
            className={className}
            style={{ ...style, whiteSpace: 'pre-wrap' }}
            placeholderStyle={placeholderStyle}
        />
    );
}
