import { useState, CSSProperties } from 'react';

import { ui } from './../';
import ImageType from '../types/frontImageType';

interface Props {
    image: ImageType | null;
    onChange: (image: ImageType | null) => void;
    src?: string;
    alt?: string;
    cropRatio?: string;
    className?: string;
    style?: CSSProperties;
    actionsProps?: any;
}

export default function Image({
    image,
    onChange,
    src = '',
    alt = '',
    cropRatio = '',
    className = '',
    style = {},
    actionsProps = { align: 'center' },
}: Readonly<Props>) {
    const [mediaBrowserVisible, setMediaBrowserVisible] = useState(false);

    const { FrontMediaBrowser, FrontActions } = ui();

    const onUse = (usedImage: ImageType) => {
        setMediaBrowserVisible(false);
        onChange(usedImage);
    };

    const selectedImage = () => {
        if (!image) return null;

        const { id, ...rest } = image;

        return { _id: id, ...rest };
    };

    const isSVG = (src: string) => {
        const regex = /\.svg(?:\?.*)?$/i;
        return regex.test(String(src));
    };

    const isDummyImage = (src: string) => {
        const url = new URL(src);
        return url.hostname.toLowerCase() === 'dummyimage.com';
    };

    const doubleDummyImageSize = (src: string) => {
        const regex = /(\d+)x(\d+)/;
        const match = src.match(regex);
        if (match) {
            const width = parseInt(match[1]);
            const height = parseInt(match[2]);
            const doubledWidth = width * 2;
            const doubledHeight = height * 2;
            return src.replace(regex, `${doubledWidth}x${doubledHeight}`);
        }
        return src;
    };

    const srcSet = (src: string) => {
        if (!src) return '';

        if (isSVG(src)) return src;

        if (isDummyImage(src)) {
            return `${src} 1x, ${doubleDummyImageSize(src)} 2x`;
        }

        return `${src}&dpr=1 1x, ${src}&dpr=2 2x`;
    };

    return (
        <>
            <img
                className={className}
                style={style}
                src={src}
                alt={alt}
                srcSet={srcSet(src)}
            />
            <FrontActions
                {...actionsProps}
                onEdit={() => setMediaBrowserVisible(true)}
                onDelete={!image ? null : () => onChange(null)}
            />
            {mediaBrowserVisible && (
                <FrontMediaBrowser
                    onClose={() => setMediaBrowserVisible(false)}
                    selectedImage={selectedImage()}
                    cropRatio={cropRatio}
                    onUse={onUse}
                />
            )}
        </>
    );
}
