import styled from 'styled-components';
import { ReactNode, ElementType, HTMLAttributes } from 'react';

import { colors } from '/css';
import { paragraphSizes } from '/css/GlobalStyles';

interface Sizes {
    large?: boolean; // p 28/38 | 24/34 | 20/28
    medium?: boolean; // p 24/36 | 20/32 | 17/26
    small?: boolean; // p 20/28 | 17/26 | 16/23
    smaller?: boolean; // p 17/26 | 17/26 | 16/23
    smallest?: boolean; // p 13/20 | 13/20 | 13/20
}

interface Weights {
    light?: boolean; // gellix-medium
    weak?: boolean; // gellix-medium grey
    strong?: boolean; // gellix-bold
}

interface Props extends Sizes, Weights, HTMLAttributes<HTMLElement> {
    children: ReactNode;
    html?: boolean; // renders children string as HTML
    centered?: boolean; // max-width with hor. auto margins
    maxWidth?: string; // in px
    as?: string;
}

const getSize = ({
    large,
    medium,
    small,
    smaller,
    smallest,
}: Sizes): string => {
    if (large) return 'large';
    if (medium) return 'medium';
    if (small) return 'small';
    if (smaller) return 'smaller';
    if (smallest) return 'smallest';

    return 'small';
};

const getWeight = ({ light, weak, strong }: Weights): string => {
    if (light) return 'light';
    if (weak) return 'weak';
    if (strong) return 'strong';

    return '';
};

const Paragraph = ({
    children,
    html,
    large,
    medium,
    small,
    smaller,
    smallest,
    light,
    weak,
    strong,
    centered,
    maxWidth,
    ...rest
}: Props) => {
    const size = getSize({ large, medium, small, smaller, smallest });
    const weight = getWeight({ light, weak, strong });

    return html ? (
        <P
            {...rest}
            size={size}
            weight={weight}
            centered={centered}
            maxWidth={maxWidth}
            dangerouslySetInnerHTML={{ __html: children }}
        />
    ) : (
        <P
            size={size}
            weight={weight}
            centered={centered}
            maxWidth={maxWidth}
            {...rest}
        >
            {children}
        </P>
    );
};

export default Paragraph;

interface StyledP extends Props {
    size: string;
    weight: string;
    children: ReactNode;
}

const P = styled.p<StyledP>`
    color: ${colors.black};
    position: relative;
    font-family: 'gellix-semi';
    text-align: ${({ centered }) => (centered ? 'center' : 'left')};
    word-break: break-word;

    ${({ maxWidth }) =>
        maxWidth
            ? `
        max-width: ${maxWidth};
        margin-left: auto;
        margin-right: auto;
    `
            : ``}

    ${({ size }) => {
        const styles = paragraphSizes.filter((s) => s.name === size)[0];

        if (!styles) return ``;

        return `
            font-size: ${styles.desktop.fs}px;
            line-height: ${styles.desktop.lh}px;
            @media (min-width: 768px) and (max-width: 1024px) {
                font-size: ${styles.tablet.fs}px;
                line-height: ${styles.tablet.lh}px;
            }
            @media(max-width: 767px) {
                font-size: ${styles.mobile.fs}px;
                line-height: ${styles.mobile.lh}px;
            }
        `;
    }}

    ${({ weight }) => {
        if (weight === 'strong') return `font-family: "gellix-bold";`;
        if (weight === 'light') return `font-family: "gellix-medium";`;
        if (weight === 'weak')
            return `font-family: "gellix-medium"; color: ${colors.grey6};`;
        return `font-family: 'gellix-semi';`;
    }}
` as ElementType;
