import { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import snakecaseKeys from 'snakecase-keys';

import LegacyInput from '../ui/LegacyInput';
import Error from '/components/ui/Error';
import Button from '/components/ui/buttons/Button';
import Description from '/components/ui/typography/Description';
import { colors } from '/css';
import authApi from '/api/auth';
import userApi from '/api/user';
import contractorApi from '/api/contractor';
import { camelcaseKeys, cookie, inputFocusBlur, isPhone } from '/lib/helpers';
import { useHydration } from '/store/store.helpers';
import { useUserState } from '/store/userStore';
import Checkbox from '/components/ui/form/Checkbox';

const PhoneVerification = (props) => {
    const user = useUserState((state) => state.user);
    const setUser = useUserState((state) => state.setUser);

    const {
        forCheckout,
        forInspection,
        forLogin,
        forOnboarding,
        forSettings,
        autoFocus,
        customError,
        confirmCodeData,
        onSuccess,
        getCodeButtonLabel,
        submitCodeButtonLabel,
        phoneNumber,
        className,
        storeUserOnSuccess,
        subscribeNewsletter,
        onSubscribeNewsletterChange,
        additionalValidation = () => true,
        onGetCodeClick = () => null,
        onConfirmCodeClick = () => null,
    } = props;

    const [phone, setPhone] = useState(phoneNumber || '');
    const [code, setCode] = useState('');
    const [codeInputVisible, setCodeInputVisible] = useState(false);
    const [submittingPhone, setSubmittingPhone] = useState(false);
    const [submittingCode, setSubmittingCode] = useState(false);
    const [errors, setErrors] = useState(customError || '');

    const phoneInput = useRef(null);
    const codeInput = useRef(null);

    const hydrated = useHydration();

    useEffect(() => {
        if (hydrated && !phone && (user?.phone || phoneNumber)) {
            setPhone(user?.phone || phoneNumber);
        }
    }, [hydrated]);

    useEffect(() => {
        setErrors(customError);
    }, [customError]);

    useEffect(() => {
        if (
            autoFocus &&
            phoneInput &&
            phoneInput.current &&
            phoneInput.current.focus
        )
            phoneInput.current.focus();
    }, []);

    const onGetCode = async () => {
        onGetCodeClick();

        if (!customError) setErrors('');

        inputFocusBlur('phone-verification-phone-input', false);
        const phoneValid = isPhone(phone);
        const additionalValidationPassed = additionalValidation();

        if (!phoneValid || !additionalValidationPassed) return;

        setSubmittingPhone(true);

        const apiCall = forLogin ? authApi.logIn : authApi.sendVerificationCode;

        const resp = await apiCall({ phone });

        setSubmittingPhone(false);

        if (resp.error) return setErrors(resp.error || 'Something went wrong');

        setCode('');
        setCodeInputVisible(true);
        setTimeout(() => {
            if (codeInput && codeInput.current && codeInput.current.focus)
                codeInput.current.focus();
        }, 500);
    };

    const onConfirmCode = async () => {
        onConfirmCodeClick();
        if (!customError) setErrors('');

        const additionalValidationPassed = additionalValidation();
        if (!additionalValidationPassed) return;

        if (!phoneInput.current.validate()) return;
        if (!codeInput.current.validate()) return;

        setSubmittingCode(true);

        let data = snakecaseKeys({
            ...(confirmCodeData || {}),
            phoneNumber: phone,
            code: code,
        });

        if (props.onConfirmCode) {
            // custom code confirmation handler
            const { error, status, message } =
                (await props.onConfirmCode(data)) || {};
            setSubmittingCode(false);
            if (error || status === 'error')
                return setErrors(error || message || 'Something went wrong');
            return;
        }

        let apiCall = null;
        if (forLogin) apiCall = authApi.confirmCode;
        if (forCheckout) apiCall = authApi.verifyPhone;
        if (forInspection) apiCall = authApi.verifyPhone;
        if (forSettings) {
            apiCall = userApi.updateUserSettings;
            data = camelcaseKeys(data);
        }
        if (forOnboarding) apiCall = contractorApi.register; // (data)

        if (!apiCall) return alert('No api call matched for code confirmation');

        const resp = await apiCall(data);

        setSubmittingCode(false);

        if (resp.error || (resp.status && resp.status === 'error')) {
            let error = resp.error || resp.message || 'Something went wrong';

            if (error === 'Unauthorized') {
                error = 'Finner ingen konto med dette telefonnummeret';
            }

            return setErrors(error);
        }

        let user = resp.user || resp.data; // resp.data is returned by settings request

        if (storeUserOnSuccess) {
            if (resp.token) cookie.set('AuthToken', resp.token, 30);
            if (user.contractor)
                cookie.set('ContractorUuid', user.contractor.uuid, 30);
            setUser(user);
        }

        onSuccess(
            user,
            resp?.token || resp.phoneVerificationToken,
            phone,
            code
        );

        if (forSettings) {
            setCodeInputVisible(false);
            setErrors(null);
            setCode('');
        }
    };

    return (
        <Wrapper className={className}>
            <div
                className={[
                    'phone-code-wrapper',
                    codeInputVisible ? 'code-in' : null,
                    codeInputVisible ? 'phone-out' : null,
                    forLogin ? 'for-login' : null,
                ].join(' ')}
            >
                <div className={['input-action-wrapper phone'].join(' ')}>
                    <LegacyInput
                        name="phone"
                        label="Mobilnummer"
                        value={phone}
                        type="tel"
                        autocomplete="tel"
                        onChange={setPhone}
                        rules={{ required: true, phone: true }}
                        ref={phoneInput}
                        onKeyPress={(e) =>
                            e.key === 'Enter' ? onGetCode() : null
                        }
                        id="phone-verification-phone-input"
                    />

                    <div className="description pt-24">
                        {forSettings ? (
                            <Description style={{ maxWidth: '370px' }}>
                                For å bekrefte at dette er ditt telefonnummer så
                                må du verifisere med kode tilsendt på SMS
                            </Description>
                        ) : (
                            <Description style={{ maxWidth: '370px' }}>
                                Vi bruker mobilnummeret til å verifisere at du
                                <br />
                                er deg og for videre kommunikasjon.
                            </Description>
                        )}
                    </div>

                    <Error
                        className="mt-32"
                        style={{ width: '100%', order: 4 }}
                    >
                        {errors}
                    </Error>

                    <Button
                        busy={submittingPhone}
                        id="get-code"
                        onClick={onGetCode}
                    >
                        <span>{getCodeButtonLabel || 'Send kode'}</span>
                    </Button>
                </div>

                <div className={['input-action-wrapper code'].join(' ')}>
                    <LegacyInput
                        name="code"
                        label="Koden"
                        value={code}
                        type="tel"
                        autocomplete="one-time-code"
                        onChange={setCode}
                        rules={{ required: true, minLength: 4 }}
                        ref={codeInput}
                        onKeyPress={(e) =>
                            e.key === 'Enter' ? onConfirmCode() : null
                        }
                    />

                    <div className="code-message">
                        En verifiseringskode er sendt til {phone} <br />
                        <span
                            className="change-phone"
                            onClick={() => {
                                setCodeInputVisible(false);
                                setErrors(null);
                                setCode('');
                            }}
                        >
                            send kode på nytt
                        </span>
                    </div>

                    {!!onSubscribeNewsletterChange && (
                        <Checkbox
                            label="Jeg ønsker å motta nyheter og kampanjer på e-post"
                            checked={subscribeNewsletter}
                            className="notifictation-type mt-32"
                            onChange={onSubscribeNewsletterChange}
                            outlined
                        />
                    )}

                    <Error className="mt-32" style={{ width: '100%' }}>
                        {errors}
                    </Error>

                    <Button
                        id="send-code"
                        className="mt-32"
                        busy={submittingCode}
                        onClick={onConfirmCode}
                    >
                        <span>{submitCodeButtonLabel || 'Neste'}</span>
                    </Button>
                </div>
            </div>
        </Wrapper>
    );
};

export default PhoneVerification;

const Wrapper = styled.div`
    position: relative;

    .phone-code-wrapper {
        position: relative;
        display: flex;
        align-items: flex-start;
        width: 200%;
        transition: all 0.5s ease-in-out;
        &.code-in {
            transform: translateX(-50%);
            .code {
                opacity: 1;
                visibility: visible;
            }
            .phone {
                opacity: 0;
                visibility: hidden;
            }
        }
    }

    .input-action-wrapper {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: flex-end;
        position: relative;
        transition: all 0.5s ease-in-out;
        width: 50%;
        .input {
            flex: 1 0 auto;
            margin-right: 12px;
            @media (max-width: 767px) {
                margin-right: 0;
                margin-bottom: 14px;
            }
        }
        button,
        .code-message {
            min-width: 200px;
            width: 200px;
            @media (max-width: 767px) {
                min-width: 100%;
                width: 100%;
            }
        }
        #get-code {
            order: 2;
            @media (max-width: 767px) {
                order: 3;
            }
        }
        .description {
            width: 100%;
            order: 3;
            @media (max-width: 767px) {
                margin-bottom: 20px;
            }
        }
        &.code {
            opacity: 0;
            visibility: hidden;
        }
        .code-message {
            color: ${colors.grey6};
            font-size: 14px;
            @media (max-width: 767px) {
                padding-top: 12px;
            }
            .change-phone {
                color: ${colors.purple};
                cursor: pointer;
            }
        }
    }

    #send-code {
        width: 100%;
    }
`;
