const naturals = {
    white: '#FFFFFF',
    grey1: '#F7F7FA',
    grey2: '#F2F2F5',
    grey3: '#EBEBED',
    grey4: '#DEDEE0',
    grey5: '#BCBCC0',
    grey6: '#949499',
    grey7: '#727276',
    grey8: '#4A4A4D',
    grey9: '#2C2C2F',
    black: '#1F1A22',
};

const purple = {
    purpleM7: '#F9F8FF',
    purpleM6: '#F4F2FF',
    purpleM5: '#EAE6FF',
    purpleM4: '#D1C9FF',
    purpleM3: '#AEA1FE',
    purpleM2: '#8E7BFE',
    purpleM1: '#765FFD',
    purple: '#5E43FD',
    purpleP1: '#563DE8',
    purpleP2: '#5039D8',
    purpleP3: '#4833C2',
    purpleP4: '#3C2AA6',
    purpleP5: '#2E1F83',
    purpleP6: '#231F5B',
    purpleP7: '#120F36',
};

const orange = {
    orangeM7: '#FFF7F2',
    orangeM6: '#FFF3ED',
    orangeM5: '#FFEBE0',
    orangeM4: '#FFDDCC',
    orangeM3: '#FFCCB2',
    orangeM2: '#FFAA80',
    orangeM1: '#FF884D',
    orange: '#FF6400',
    orangeP1: '#DA4812',
    orangeP2: '#C73B07',
    orangeP3: '#AB2F02',
    orangeP4: '#8A2500',
    orangeP5: '#6C1D00',
    orangeP6: '#481300',
    orangeP7: '#390F00',
};

const mint = {
    mintM7: '#F4FDFC',
    mintM6: '#E9FAF9',
    mintM5: '#DAF7F5',
    mintM4: '#C7F3EF',
    mintM3: '#A1EBE5',
    mintM2: '#7CE3DB',
    mintM1: '#60DDD3',
    mint: '#44D7CB',
    mintP1: '#24C6B8',
    mintP2: '#0FB1A4',
    mintP3: '#079589',
    mintP4: '#037E75',
    mintP5: '#02655D',
    mintP6: '#004842',
    mintP7: '#003D38',
};

const blue = {
    blueM7: '#F5F9FF',
    blueM6: '#E8EFFA',
    blueM5: '#DDE8FA',
    blueM4: '#C2DAFF',
    blueM3: '#9FC4FD',
    blueM2: '#78ADFF',
    blueM1: '#458CFC',
    blue: '#2477F9',
    blueP1: '#0F64EB',
    blueP2: '#0757D3',
    blueP3: '#0047B5',
    blueP4: '#003993',
    blueP5: '#002868',
    blueP6: '#001A44',
    blueP7: '#001433',
};

const yellow = {
    yellowM7: '#FFFEF7',
    yellowM6: '#FFFCE9',
    yellowM5: '#FDF9E0',
    yellowM4: '#FDF6C8',
    yellowM3: '#FDF3B1',
    yellowM2: '#FDF091',
    yellowM1: '#FDEB6A',
    yellow: '#FCE542',
    yellowP1: '#F3DA29',
    yellowP2: '#E5CC18',
    yellowP3: '#D6BD09',
    yellowP4: '#C6AE02',
    yellowP5: '#A08D00',
    yellowP6: '#766800',
    yellowP7: '#5A4F00',
};

const green = {
    greenM7: '#F7FFF6',
    greenM6: '#EEFDEB',
    greenM5: '#DCFCD6',
    greenM4: '#C7F9B3',
    greenM3: '#B6F69C',
    greenM2: '#A6F486',
    greenM1: '#96F26F',
    green: '#86F059',
    greenP1: '#74D655',
    greenP2: '#61BC52',
    greenP3: '#4EA14E',
    greenP4: '#3C874A',
    greenP5: '#30713F',
    greenP6: '#245A35',
    greenP7: '#18442A',
};

const red = {
    redM7: '#FFF9F9',
    redM6: '#FEF2F1',
    redM5: '#FBD2C5',
    redM4: '#F3947C',
    redM3: '#F3947C',
    redM2: '#F0785F',
    redM1: '#EC5743',
    red: '#E92B2C',
    redP1: '#D52829',
    redP2: '#C52426',
    redP3: '#B52224',
    redP4: '#A61E21',
    redP5: '#981B1E',
    redP6: '#8A181A',
    redP7: '#760E10',
};

const colors = {
    ...naturals,
    ...purple,
    ...orange,
    ...mint,
    ...blue,
    ...yellow,
    ...green,
    ...red,
    text: {
        heading: naturals.black,
        body: naturals.black,
        body1: naturals.grey9,
        body2: naturals.grey8,
        dimmed: naturals.grey7,
        link: purple.purple,
        white: naturals.white,
    },
    card: {
        strike: naturals.grey2,
    },
    background: {
        white: naturals.white,
    },
    brand: {
        primary: purple.purple,
        secondary: orange.orange,
        white: naturals.white,
        black: naturals.black,
    },
    statuses: {
        success: green.green,
        successDark: green.greenP6,
        successLight: green.greenM6,
        error: red.red,
        errorDark: red.redP6,
        errorLight: red.redM6,
        warning: yellow.yellow,
        warningDark: yellow.yellowP7,
        warningLight: yellow.yellowM5,
        info: purple.purple,
        infoDark: purple.purpleP5,
        infoLight: purple.purpleM6,
        disabled: naturals.grey6,
        disabledDark: naturals.grey8,
        disabledLight: naturals.grey1,
        concern: orange.orangeM2,
        concerDark: orange.orangeP6,
        concernLight: orange.orangeM7,
    },
    video: {
        purpleShade1: '#1D1B42',
        purpleShade2: '#22204C',
        purpleShade3: '#2F2D56',
        purpleShade4: '#36345B',
        purpleShade5: '#3D3B64',
        lightRed: red.redM6,
        lightRedHovered: '#FFEAE4',
        lightRedPressed: '#FFDFD5',
        red: red.red,
        redHovered: '#CE1617',
        redPressed: '#BB090A',
    },
};

export default colors;
