import moment from 'moment';

export const formatDate = (dateString: string, format = 'DD. MMMM YYYY') => {
    if (!dateString) return '';

    const date = moment(dateString);

    return date.isValid() ? `${date.format(format)}` : '';
};

export default formatDate;
