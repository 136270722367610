import styled from 'styled-components';
import Label from '../typography/Label';
import React, { HTMLAttributes, ReactElement, useMemo } from 'react';
import { colors } from '/css';
import InputValidationErrors from './InputValidationErrors';
import randomString from '/lib/utils/randomString';

interface Props extends HTMLAttributes<HTMLDivElement> {
    label?: string;
    errors?: string[];
    children: ReactElement;
    optional?: boolean;
}

const FormControl = ({ label, errors, children, optional, ...rest }: Props) => {
    const internalOptional = optional || !!children.props.optional;
    const id = useMemo(() => {
        if (children.props.id) return children.props.id;
        let id = randomString();
        try {
            if (Array.isArray(children.props.children)) {
                children.props.children.forEach((child: any) => {
                    if (child?.props?.id) {
                        id = child.props.id;
                    }
                });
            }
        } catch (e) {}

        return id;
    }, [children.props.id]);

    const enhancedInput = React.cloneElement(children, {
        id,
        invalid: !!errors?.length,
    });

    return (
        <Component {...rest}>
            {label && (
                <StyledLabel htmlFor={id} suppressHydrationWarning>
                    {label}
                    {internalOptional && (
                        <span className="optional">Valgfritt</span>
                    )}
                </StyledLabel>
            )}
            {enhancedInput}
            <InputValidationErrors errors={errors} />
        </Component>
    );
};

const StyledLabel = styled(Label)`
    margin-bottom: 8px;
    display: block;

    .optional {
        font-size: 15px;
        line-height: 23px;
        color: ${colors.grey6};
        padding-left: 7px;
    }
`;

const Component = styled.div``;

export default FormControl;
