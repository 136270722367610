import { TemplateJobLineItem } from '/types/lineItemTypes';
import { JobTemplate, JobTemplateType } from '/types/jobTemplateTypes';

export default function lineItemToJob(
    lineItem: TemplateJobLineItem
): JobTemplate {
    return {
        typeKey: lineItem.data.typeKey,
        jobTypeId: lineItem.data.jobTypeId,
        jobTypeVersionId: lineItem.data.jobTypeVersionId,
        jobTypeVersion: lineItem.data.jobTypeVersion,
        materials: lineItem.data.materials,
        published: lineItem.data.published,
        order: lineItem.data.order,
        totals: lineItem.data.totals,
        vismaArticleNumber: lineItem.data.vismaArticleNumber,
        workHours: lineItem.data.workHours,
        images: lineItem.data.images,
        commentForElectrician: lineItem.data.commentForElectrician,
        materialsEdited: lineItem.data.materialsEdited || false,
        editedMaterialList: lineItem.data.editedMaterialList,
        expiredMaterials: [],
        answers: lineItem.data.answers,
        versions: [],
    };
}
