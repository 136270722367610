import { useMemo } from 'react';
import dynamic from 'next/dynamic';
import { atom, useAtom } from 'jotai';
import config from '../config';

export const editModeAtom = atom(false);

const Importer = () => {
    const _ = (_: any) => null;

    const { canUseRichText } = config.useUser();
    const [editMode, setEditMode] = useAtom(editModeAtom);

    return useMemo(
        () => ({
            editMode,
            setEditMode,
            FrontRichTextEditor: !canUseRichText
                ? _
                : dynamic(() => import('./FrontRichTextEditor'), {
                      ssr: false,
                  }),
            FrontActions: !canUseRichText
                ? _
                : dynamic(() => import('./FrontActions'), { ssr: false }),
            FrontButton: !canUseRichText
                ? _
                : dynamic(() => import('./FrontButton'), { ssr: false }),
            FrontCheckbox: !canUseRichText
                ? _
                : dynamic(() => import('./FrontCheckbox'), { ssr: false }),
            FrontConfirmButton: !canUseRichText
                ? _
                : dynamic(() => import('./FrontConfirmButton'), { ssr: false }),
            FrontEditable: !canUseRichText
                ? _
                : dynamic(() => import('./FrontEditable'), { ssr: false }),
            FrontInput: !canUseRichText
                ? _
                : dynamic(() => import('./FrontInput'), { ssr: false }),
            FrontTagInput: !canUseRichText
                ? _
                : dynamic(() => import('./FrontTagInput'), { ssr: false }),
            FrontImageInput: !canUseRichText
                ? _
                : dynamic(() => import('./FrontImageInput'), { ssr: false }),
            FrontLabel: !canUseRichText
                ? _
                : dynamic(() => import('./FrontLabel'), { ssr: false }),
            FrontLinkPicker: !canUseRichText
                ? _
                : dynamic(() => import('./FrontLinkPicker'), { ssr: false }),
            FrontPagePicker: !canUseRichText
                ? _
                : dynamic(() => import('./FrontPagePicker'), { ssr: false }),
            FrontLoader: !canUseRichText
                ? _
                : dynamic(() => import('./FrontLoadingCircle'), { ssr: false }),
            FrontMediaBrowser: !canUseRichText
                ? _
                : dynamic(() => import('./FrontMediaBrowser'), { ssr: false }),
            FrontModal: !canUseRichText
                ? _
                : dynamic(() => import('./FrontModal'), { ssr: false }),
            FrontOverlayLock: !canUseRichText
                ? _
                : dynamic(() => import('./FrontOverlayLock'), { ssr: false }),
            FrontRadio: !canUseRichText
                ? _
                : dynamic(() => import('./FrontRadio'), { ssr: false }),
            FrontSelect: !canUseRichText
                ? _
                : dynamic(() => import('./FrontSelect'), { ssr: false }),
            FrontSwitch: !canUseRichText
                ? _
                : dynamic(() => import('./FrontSwitch'), { ssr: false }),
            FrontTabs: !canUseRichText
                ? _
                : dynamic(() => import('./FrontTabs'), { ssr: false }),
            FrontBars: !canUseRichText
                ? _
                : dynamic(() => import('./FrontLoadingBars'), { ssr: false }),
            FrontColorPicker: !canUseRichText
                ? _
                : dynamic(() => import('./FrontColorPicker'), { ssr: false }),
            FrontSlider: !canUseRichText
                ? _
                : dynamic(() => import('./FrontSlider'), { ssr: false }),
        }),
        [editMode, canUseRichText]
    );
};

export default Importer;
