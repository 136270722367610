import api from './api';
import { UserRole, UserType } from '/types/userTypes';
import { Error } from '/types/apiResponseTypes';
import DepartmentType from '/types/departmentType';
import snakecaseKeys from 'snakecase-keys';

interface SomeOfUser extends Partial<UserType> {}

const userApi = {
    index: async (roles: UserRole[]): Promise<{ data: UserType[] } | Error> => {
        const rolesQuery = roles.map((r) => `roles[]=${r}`).join('&');
        return api.getSecure(`user?${rolesQuery}`);
    },

    getCaseworkerDepartments: async (): Promise<
        { data: DepartmentType[] } | Error
    > => api.getSecure('user/caseworker-departments'),

    updateCaseworkerDepartments: async (
        departmentIds: number[]
    ): Promise<{ data: UserType } | Error> =>
        api.patch('user/caseworker-departments', departmentIds),

    setActiveRoles: (roles: UserRole[]): Promise<{ data: UserType } | Error> =>
        api.post(`user/set-active-roles`, roles),

    guidedTourFinished: (
        tourName: string
    ): Promise<{ data: UserType } | Error> =>
        api.post(`user/guided-tour-finished`, { tour: tourName }),

    updateUserSettings: (
        userdata: SomeOfUser
    ): Promise<{ data: UserType } | Error> =>
        api.patch('user', snakecaseKeys(userdata)),
};

export default userApi;
