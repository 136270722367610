import { useState } from 'react';

import { ui, config } from './../';
import LinkType from '../types/frontLinkType';
import ButtonType from '../types/frontButtonType';

interface Props {
    value: LinkType | ButtonType | null;
    onChange: (value: LinkType) => void;
    children?: any;
    [key: string]: any;
}

export default function Link({
    value,
    onChange,
    children = '',
    ...rest
}: Readonly<Props>) {
    const [linkPickerVisible, setLinkPickerVisible] = useState(false);

    const { editMode, FrontActions, FrontLinkPicker } = ui();

    const LinkComponent = config.presentation?.Link?.linkComponent || 'a';

    if (!editMode)
        return (
            <LinkComponent {...rest}>
                {children || value?.title || 'link'}
            </LinkComponent>
        );

    const onLink = (linkData: LinkType) => {
        onChange(linkData);
        setLinkPickerVisible(false);
    };

    return (
        <div style={{ position: 'relative', display: 'inline-block' }}>
            <LinkComponent {...rest}>
                {children || value?.title || 'link'}
            </LinkComponent>
            <FrontActions
                align="center"
                onEdit={() => setLinkPickerVisible(true)}
            />
            {linkPickerVisible && (
                <FrontLinkPicker
                    title={value?.title || ''}
                    url={value?.url || ''}
                    target={value?.target || '_self'}
                    type={(value?.type as string) || ''}
                    id={value?.id || ''}
                    slug={value?.slug || ''}
                    onClose={() => setLinkPickerVisible(false)}
                    onLink={onLink}
                    serchableTypes={['page', 'job']}
                />
            )}
        </div>
    );
}
