import api from './api';
import { Error } from '/types/apiResponseTypes';
import Settings, { PublicSettings } from '/types/SettingsType';

interface SomeOfSettings extends Partial<Settings> {}

const settingsApi = {
    getAll: (token?: string): Promise<Settings | Error> =>
        api.getSecure('settings', token),

    getPublic: (token?: string): Promise<PublicSettings | Error> =>
        api.getSecure('settings/public', token),

    update: (settings: SomeOfSettings): Promise<Settings | Error> =>
        api.patch('settings', settings),
};

export default settingsApi;
