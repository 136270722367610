import styled, { css } from 'styled-components';
import { ReactNode, useState, useEffect } from 'react';
import { colors } from '/css';

interface Props {
    label?: string | ReactNode;
    defaultChecked?: boolean;
    checked?: boolean;
    disabled?: boolean;
    onChange: (checked: boolean) => void;
    className?: string;
    outlined?: boolean;
    strong?: boolean;
}

const Checkbox = ({
    label,
    onChange,
    defaultChecked = true,
    checked = true,
    disabled = false,
    className = '',
    outlined = false,
    strong = false,
}: Props) => {
    const [isChecked, setIsChecked] = useState(defaultChecked);

    const handleChange = () => {
        onChange(!isChecked);
        setIsChecked(!isChecked);
    };

    useEffect(() => {
        setIsChecked(checked);
    }, [checked]);

    return (
        <Label
            className={className}
            disabled={disabled}
            outlined={outlined}
            strong={strong}
        >
            <input
                type="checkbox"
                value={1}
                checked={isChecked}
                className="checkbox"
                onChange={handleChange}
                disabled={disabled}
            />
            {label && <span className="label">{label}</span>}
        </Label>
    );
};

export default Checkbox;

const Label = styled.label<{
    disabled: boolean;
    outlined: boolean;
    strong: boolean;
}>`
    font-size: 17px;
    color: #1f1a22;
    font-family: 'gellix-medium';
    display: inline-flex;
    align-content: center;
    cursor: pointer;
    user-select: none;

    ${(p) =>
        p.disabled &&
        css`
            color: #75757f;
            cursor: default;
            input {
                cursor: default;
                background-color: ${colors.grey1};
            }

            pointer-events: none;
        `};

    ${(p) =>
        p.outlined &&
        css`
            padding: 16px;
            border-radius: 12px;
            border: 3px solid ${colors.grey1};
            width: 100%;
            align-items: center;
        `};

    ${(p) =>
        p.strong &&
        css`
            font-family: 'gellix-semi';
        `};
`;
